
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component
export default class ListItem extends Vue {
  @Prop({ required: true, type: Number })
  id!: number;

  @Prop({ required: true, type: Boolean })
  isActive!: boolean;

  @Prop({ required: true, type: String })
  label!: string;

  @Prop({ type: Function })
  select!: (id: number) => void;

  @Prop({ default: false })
  hasWarning!: boolean;

  @Prop()
  checkedState!: 'checked' | 'intermediate' | 'unchecked';

  get checked() {
    return this.checkedState === 'checked';
  }

  get intermediate() {
    return this.checkedState === 'intermediate';
  }

  onClick() {
    if (!this.select) return;
    this.select(this.id);
  }

  onCheck({ target }: any) {
    this.$emit('change', target.checked);
  }

  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }
}
