import Vue from 'vue';
import Component from 'vue-class-component';

import { SportStore } from '@/modules/sport';
import { Watch } from 'vue-property-decorator';

@Component
export class WithSportAndTemplate extends Vue {
  selectedSport: null | number = null;
  selectedTemplate: null | number = null;

  @Watch('sports')
  onSports() {
    if (this.selectedSport === null) {
      this.selectedSport = this.sports[0].id;
    }
  }

  get sports() {
    return SportStore.sortedAllSports;
  }

  get isSportSelected() {
    return (id: number) => id === this.selectedSport;
  }

  get isTemplateSelected() {
    return (id: number) => id === this.selectedTemplate;
  }

  mounted() {
    SportStore.fetchSports();
  }

  selectSport(id: number) {
    this.selectedSport = id;
    this.selectedTemplate = 0;
  }

  selectTemplate(id: number) {
    this.selectedTemplate = id;
  }
}
